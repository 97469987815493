import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require('modaal/dist/js/modaal.min.js');
//console.log('common');

$('.js-pickup-modaal-btn').each(function() {
    var contentSource = '#' + $(this).data('content');
    $(this).modaal({
        content_source: contentSource,
        before_open: function() {
            $(contentSource).show();
        },
        after_close: function() {
            $(contentSource).hide();
        },
    });
});
