import $ from 'jquery';
import '../Components/_Anchor.js';
import '../Components/_Modal.js';

$(function() {
    $(".c-header__open_btn").on("click", function(e) {
        e.preventDefault();

        // ナビの状態をトグルする
        $(this).toggleClass("active");
        $('.c-sp-nav').toggleClass('active');

        // navIsOpenの値を更新
        var navIsOpen = $('.c-sp-nav').hasClass('active');

        // ナビの状態に応じて背景のスクロールを制御
        if (navIsOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    });
});
